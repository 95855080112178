exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-ankauf-js": () => import("./../../../src/pages/ankauf.js" /* webpackChunkName: "component---src-pages-ankauf-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-datenschutz-js": () => import("./../../../src/pages/datenschutz.js" /* webpackChunkName: "component---src-pages-datenschutz-js" */),
  "component---src-pages-impressum-js": () => import("./../../../src/pages/impressum.js" /* webpackChunkName: "component---src-pages-impressum-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-verkauf-copy-js": () => import("./../../../src/pages/verkauf copy.js" /* webpackChunkName: "component---src-pages-verkauf-copy-js" */),
  "component---src-pages-verkauf-formular-js": () => import("./../../../src/pages/verkauf-formular.js" /* webpackChunkName: "component---src-pages-verkauf-formular-js" */),
  "component---src-pages-verkauf-js": () => import("./../../../src/pages/verkauf.js" /* webpackChunkName: "component---src-pages-verkauf-js" */),
  "component---src-pages-zubehoer-js": () => import("./../../../src/pages/zubehoer.js" /* webpackChunkName: "component---src-pages-zubehoer-js" */),
  "component---src-templates-using-dsg-js": () => import("./../../../src/templates/using-dsg.js" /* webpackChunkName: "component---src-templates-using-dsg-js" */)
}

